import React from 'react';

import Footer from '../components/global-components/Footer';
import Newsletter from '../components/global-components/Newsletter';
import WhatAreYouLookingFor from '../components/section-components/WhatAreYouLookingFor';
import SearchPanel from '../components/global-components/SearchPanel';
import Navbar2 from '../components/global-components/Navbar2';
import PageHeader from '../components/global-components/PageHeader';
import Main from '../components/global-components/Main';

/**
 * 
 * @returns 
 */
const OurOffers = () => {

    return (
        <>
            <Navbar2 />
            <Main>
                <div className='wrapper'>
                    <PageHeader
                        headertitle='Nos offres'
                        // breadcrumbs={['Que cherchez-vous ?']}
                    />
                    <WhatAreYouLookingFor />
                    <Newsletter />
                </div>
            </Main>
            <Footer />
            <SearchPanel />
        </>
    );
};

export default OurOffers;
