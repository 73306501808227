import React from 'react';

import Navbar2 from '../components/global-components/Navbar2';
// import PageHeader from '../components/global-components/PageHeader';
import Error from '../components/section-components/error';
import Footer from '../components/global-components/Footer';
import Main from '../components/global-components/Main';

/**
 * 
 * @returns 
 */
const ErrorPage = () => {
    return (
        <>
            <Navbar2 />
            <Main>
                <div className='wrapper'>
                    {/* <PageHeader headertitle="404 Page" /> */}
                    <div className='py-4'></div>
                    <Error />
                </div>
            </Main>
            <Footer />
        </>
    );
};

export default ErrorPage;
