import React from 'react';

import Navbar2 from '../components/global-components/Navbar2';
import Main from '../components/global-components/Main';
import PageHeader from '../components/global-components/PageHeader';
import Content from '../components/real-estate-components/house-components/Content';
import Newsletter from '../components/global-components/Newsletter';
import Footer from '../components/global-components/Footer';

/**
 * 
 * @returns 
 */
const Houses = () => {
    return (
        <>
            <Navbar2 />
            <Main>
                <div className='wrapper'>
                    <PageHeader headertitle="Maisons" />
                    <Content />
                    <Newsletter />
                </div>
            </Main>
            <Footer />
        </>
    );
}

export default Houses;
