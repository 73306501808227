import React from "react";

import { Link } from "react-router-dom";
import parse from "html-react-parser";

/**
 * 
 * @returns 
 */
const WhatAreYouLookingFor = () => {
    const publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    const list = [
        {
            title: 'Terrain TF',
            icon: 'flaticon-official-documents',
            url: '/terrains/lotissements/'
        },
        {
            title: 'Appartement meublé',
            icon: 'flaticon-apartment-1',
            url: '/appartements'
        },
        {
            title: 'Maison clef en main',
            icon: 'flaticon-house-key',
            url: '/maisons'
        },
        {
            title: 'Terrain agricole',
            icon: 'flaticon-official-documents',
            url: '/terrains/parcelles-non-loties'
        },
        {
            title: 'Maison de vacances',
            icon: 'flaticon-home-2',
            url: '/maisons'
        },
        {
            title: 'Demander un devis de plan',
            icon: 'flaticon-buy-home',
            url: '/contact'
        },
        // {
        //     title: 'Faire construire une maison',
        //     icon: 'flaticon-slider',
        //     url: ''
        // },
        // {
        //     title: "La sélection Alfa par l'équipe",
        //     icon: '',
        //     url: ''
        // },
    ];

    return (
        <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-115 pb-90 go-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            {/* <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Nos offres</h6> */}
                            <h2 className="section-title">Que cherchez-vous ?</h2>
                        </div>
                    </div>
                </div>
                <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center">
                {
                    list.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className="col-lg-4 col-md-4 col-sm-6 col-6"
                            >
                                <div className="ltn__category-item ltn__category-item-5 text-center">
                                    <Link to={item.url}>
                                        <span className="category-icon">
                                            <i className={item.icon} />
                                        </span>
                                        <span className="category-title">{item.title}</span>
                                        <span className="category-btn">
                                            <i className="flaticon-right-arrow" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        );
                    })
                }
                </div>
            </div>
        </div>
    );
};

export default WhatAreYouLookingFor;
