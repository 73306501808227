import React from 'react';

import Navbar from '../components/global-components/Navbar2';
import Main from '../components/global-components/Main';
import PageHeader from '../components/global-components/PageHeader';
import ContactInfo from '../components/section-components/ContactInfo';
import ContactForm from '../components/section-components/ContactForm';
import Map from '../components/section-components/Map';
import Footer from '../components/global-components/Footer';
import Newsletter from '../components/global-components/Newsletter';

/**
 * 
 * @returns 
 */
const Contact = () => {
    return (
        <>
            <Navbar />
            <Main>
                <div className='wrapper'>
                    <PageHeader
                        headertitle="Contact"
                        // subheader="Contact"
                        // breadcrumbs={['Concevez avec nous votre projet de A à Z']}
                    />
                    <ContactInfo />
                    <ContactForm />
                    <Map />
                    <Newsletter />
                </div>
            </Main>
            <Footer />
        </>
    );
};

export default Contact;
