/**
 * 
 * @param {*} realEstate 
 * @returns 
 */
export const getStatus = (realEstate) => {
    switch (realEstate.status) {
        case 'RENT':
            return 'À louer';
        case 'SALE':
            return 'À vendre';
        case 'RESERVED':
            return 'Réservé';
        case 'RENTED':
            return 'Loué';
        case 'SOLD':
            return 'Vendu';
        default:
            break;
    }
};

/**
 * 
 * @param {*} realEstate 
 * @returns 
 */
export const getBadgeColorByStatus = (realEstate) => {
    switch (realEstate.status) {
        case 'RENT':
        case 'SALE':
            return 'bg-green';
        case 'RESERVED':
            return '';
        case 'RENTED':
            return '';
        case 'SOLD':
            return '';
        default:
            break;
    }
};

/**
 * 
 * @param {*} realEstate 
 * @returns 
*/
export const getImageFilePath = (realEstate) =>{

    const publicUrl = process.env.PUBLIC_URL + "/";

    switch (realEstate.real_estate.type) {
        case 'LAND':
            // return `${publicUrl}assets/img/lands/${realEstate.id}/${realEstate.real_estate.images[0].file_name}`;
            return `${publicUrl}assets/img/lands/image-terrain-${realEstate.real_estate.municipality_id === 432 ? 'pout' : 'sangalkam'}.jpg`;
        case 'HOUSE':
            return `${publicUrl}assets/img/houses/${realEstate.id}/${realEstate.real_estate.images[0].file_name}`;
        case 'APARTMENT':
            return `${publicUrl}assets/img/apartments/${realEstate.id}/${realEstate.real_estate.images[0].file_name}`;
        default:
            break;
    }
};
