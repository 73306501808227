import React from "react";
import { Link } from "react-router-dom";

import Social from "../section-components/SocialsNetwork";
import Copyright from "./Copyright";
import { useDataContext } from "../../data/providers/DataProvider";

/**
 * 
 * @returns 
 */
const Main = ({children}) => {

    return (
        <main className="">
        {
            children
        }
        </main>
    );
}

export default Main;
