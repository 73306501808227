import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import Navbar2 from '../../../components/global-components/Navbar2';
import PageHeader from '../../../components/global-components/PageHeader';
import Newsletter from '../../../components/global-components/Newsletter';
import Footer from '../../../components/global-components/Footer';
import PlotDetails from '../../../components/real-estate-components/land-components/subdivisions/plots/PlotDetails';
import { useLoaderSpinnerContext } from '../../../components/global-components/providers/LoaderSpinnerProvider';
import { useDataContext } from '../../../data/providers/DataProvider';
import Main from '../../../components/global-components/Main';

/**
 * 
 * @returns 
 */
const Plot = () => {

    const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { lands } = useDataContext();
    const { id: landId } = useParams();
    const [land, setLand] = useState(undefined);

    useEffect(() => {

        if(land){
            setShowLoaderSpinner(false);
        }

    }, [land, setShowLoaderSpinner]);

    useEffect(() => {

        const _land = lands.find((l) => l.id === Number(landId));
        setLand(_land);

    }, [landId, lands]);

    const publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <>
            <Navbar2 />
            <Main>
                <div className='wrapper'>
                {
                    land && <PageHeader
                        // headertitle="Terrain"
                        customclass="mb-0"

                        headertitle={`${land.real_estate.wording} lot n°${land.number}`}
                        breadcrumbs={['terrains', land.subdivision_id ? 'lotissements' : 'parcelles non loties', `${land.real_estate.wording} lot n°${land.number}`]}
                    />
                }
                    {/* <LandSlider land={land} /> */}
                    {/* {land && <LandSlider land={land} />} */}
                    <div
                        className="ltn__sub-breadcrumb-area bg-overlay-theme-black-10 position-relative bg-image w-100"
                        // data-bs-bg={`${publicUrl}assets/img/bg/img-${land && land.real_estate.municipality_id === 466 ? '3' : '2'}.jpg`}
                        style={{backgroundImage: `url('${publicUrl}assets/img/bg/img-${land && land.real_estate.municipality_id === 466 ? '3' : '2'}.jpg')`}}
                    ></div>
                    <div className='py-3'></div>
                    {land && <PlotDetails land={land} />}
                    <Newsletter />
                </div>
            </Main>
            <Footer />
        </>
    );
};

export default Plot;
