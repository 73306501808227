import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import Navbar2 from '../components/global-components/Navbar2';
import Main from '../components/global-components/Main';
import PageHeader from '../components/global-components/PageHeader';
import HouseSlider from '../components/real-estate-components/house-components/HouseSlider';
import HouseDetails from '../components/real-estate-components/house-components/HouseDetails';
import Newsletter from '../components/global-components/Newsletter';
import Footer from '../components/global-components/Footer';
import { useDataContext } from '../data/providers/DataProvider';
import { useLoaderSpinnerContext } from '../components/global-components/providers/LoaderSpinnerProvider';

/**
 * 
 * @returns 
 */
const House = () => {

    const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { houses } = useDataContext();
    const { id: houseId } = useParams();
    const [house, setHouse] = useState(undefined);

    useEffect(() => {

        if(house){
            setShowLoaderSpinner(false);
        }

    }, [house, setShowLoaderSpinner]);

    useEffect(() => {

        const _house = houses.find((h) => h.id === Number(houseId));
        setHouse(_house);

    }, [houseId, houses]);

    return ( 
        <>
            <Navbar2 />
            <Main>
                <div className='wrapper'>
                    <PageHeader
                        headertitle="Maison"
                        customclass="mb-0"
                    />
                    {/* <HouseSlider house={house} /> */}
                    { house && <HouseSlider house={house} />}
                    {/* <div
                        className="ltn__sub-breadcrumb-area bg-overlay-theme-black-10 position-relative bg-image w-100"
                        data-bs-bg={`${publicUrl}assets/img/bg/img-${land && land.real_estate.municipality_id === 466 ? '3' : '2'}.jpg`}
                        style={{
                            zIndex: 0, 
                            minHeight: '350px',
                            // backgroundImage: `url(${publicUrl}assets/img/bg/img-${land && land.real_estate.municipality_id === 466 ? '3' : '2'}.jpg`
                        }}
                    ></div> */}
                    <div className='py-3'></div>
                    { house && <HouseDetails house={house} />}
                    <Newsletter />
                </div>
            </Main>
            <Footer />
        </>
    );
};

export default House;
