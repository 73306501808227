import React from 'react';

import House from './House';
import Apartment from './Apartment';
import Building from './Building';
import FeetOnTheGround from './FeetOnTheGround';
import InteriorDesign from './InteriorDesign';
import Form from './Form';

/**
 * 
 * @param {*} props 
 * @returns 
 */
const RequestForQuotes = (props = null) => {

    const publicUrl = process.env.PUBLIC_URL+'/'
	const CustomClass = props.CustomClass ? props.CustomClass : ''

    // const tabs = ['Maison', 'Appartement', 'Immeuble', 'Pieds à terre', "Design d'intérieur"];

    return (
        <div className={"ltn__apartments-plan-area pt-115--- pb-70 " + CustomClass}>
            <div className="container">
                {/* <div className="row"> */}
                    {/* <div className="col-lg-12"> */}
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            {/* <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Accompagnement</h6> */}
                            <h3 className="section-title">Concevez avec nous votre projet de A à Z</h3>
                        </div>
                        <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
                            <div className="nav">
                                <a className="active show" data-bs-toggle="tab" href="#quote_tab_3_1">Maison</a>
                                <a data-bs-toggle="tab" href="#quote_tab_3_2">Appartement</a>
                                <a data-bs-toggle="tab" href="#quote_tab_3_3" >Immeuble</a>
                                <a data-bs-toggle="tab" href="#quote_tab_3_4" >Pied-à-terre</a>
                                <a data-bs-toggle="tab" href="#quote_tab_3_5" >Design d'intérieur</a>
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-lg-8 col-xl-7 order-2 order-lg-1'>
                                <Form />
                            </div>
                            <div className='col-lg-4 col-xl-5 order-1 order-lg-2'>
                                <div className="tab-content">
                                    <div className="tab-pane fade active show" id="quote_tab_3_1">
                                        <div className="ltn__apartments-tab-content-inner">
                                            <House />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="quote_tab_3_2">
                                        <div className="ltn__product-tab-content-inner">
                                            <Apartment />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="quote_tab_3_3">
                                        <div className="ltn__product-tab-content-inner">
                                            <Building />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="quote_tab_3_4">
                                        <div className="ltn__product-tab-content-inner">
                                            <FeetOnTheGround />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="quote_tab_3_5">
                                        <div className="ltn__product-tab-content-inner">
                                            <InteriorDesign />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                {/* </div> */}
            </div>
        </div>
    );
};

export default RequestForQuotes;
