import React from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";

// import "./SlideShow.css";

// import { WOW } from "wowjs";

/**
 * 
 * @returns 
 */
const HomeSlides = () => {

    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
        <div
            id="homeSlides"
            className="home-sildes ltn__slider-area ltn__slider-3 section-bg-2"
        >
            <Slider
                autoplay={true}
                autoplaySpeed={12000}
                pauseOnHover={false}
                pauseOnFocus={false}
                arrows={true}
                dots={true}
                fade={true}
                cssEase="linear"
                infinite={true}
                speed={300}
                slidesToShow={1}
                slidesToScroll={1}
                prevArrow={<button type="button" className="slick-prev"><i className="fas fa-arrow-left" alt="Arrow Icon"></i></button>}
                nextArrow={<button type="button" className="slick-next"><i className="fas fa-arrow-right" alt="Arrow Icon"></i></button>}
                responsive={[
                    {
                        breakpoint: 1200,
                        settings: {
                            arrows: false,
                            dots: true,
                        }
                    }
                ]}
                className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1"
            >
                {/* ltn__slide-item */}
                <div
                    className="home-slide-item ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 bg-image bg-overlay-theme-black-40"
                    // style={{backgroundImage: `url('${publicUrl}assets/img/bg/img-6.jpg')`}}
                    data-bs-bg={`${publicUrl}assets/img/bg/img-6.jpg`}
                >
                    <div className="ltn__slide-item-inner text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 align-self-center">
                                    <div className="slide-item-info">
                                        <div className="slide-item-info-inner ltn__slide-animation">
                                            <div className="slide-video mb-50 d-none">
                                                <a
                                                    className="ltn__video-icon-2 ltn__video-icon-2-border"
                                                    href="https://www.youtube.com/embed/tlThdr3O5Qo"
                                                    data-rel="lightcase:myCollection"
                                                >
                                                    <i className="fa fa-play" />
                                                </a>
                                            </div>
                                            <h3 className="slide-titlee animated ">
                                                De beaux appartements <br />{" "}
                                                lumineux !
                                            </h3>
                                            <div className="slide-brief animated">
                                                <p>
                                                    Contactez-nous dès
                                                    aujourd'hui pour plus
                                                    d'informations ou pour
                                                    planifier une visite.
                                                </p>
                                            </div>
                                            <div className="btn-wrapper animated go-top">
                                                <Link
                                                    to="/appartements"
                                                    className="theme-btn-1 btn btn-effect-1 py-2 px-4"
                                                >
                                                    Découvrir
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ltn__slide-item */}
                <div
                    className="home-slide-item ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 bg-image bg-overlay-theme-black-40"
                    // style={{backgroundImage: `url('${publicUrl}assets/img/bg/img-3.jpg')`}}
                    data-bs-bg={`${publicUrl}assets/img/bg/img-3.jpg`}
                >
                    <div className="ltn__slide-item-inner text-right text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 align-self-center">
                                    <div className="slide-item-info">
                                        <div className="slide-item-info-inner ltn__slide-animation">
                                            <h3 className="slide-titlee animated ">
                                                Trouvez le terrain idéal
                                                <br /> pour votre projet !
                                            </h3>
                                            <div className="slide-brief animated">
                                                <p>
                                                    Contactez-nous dès
                                                    aujourd'hui pour plus
                                                    d'informations ou pour
                                                    planifier une visite.
                                                </p>
                                            </div>
                                            <div className="btn-wrapper animated go-top">
                                                <Link
                                                    to="/terrains/lotissements"
                                                    className="theme-btn-1 btn btn-effect-1 py-2 px-4"
                                                >
                                                    Découvrir
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ltn__slide-item */}
                <div
                    className="home-slide-item ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 bg-image bg-overlay-theme-black-40"
                    // style={{backgroundImage: `url('${publicUrl}assets/img/bg/img2.png')`}}
                    data-bs-bg={`${publicUrl}assets/img/bg/img2.png`}
                >
                    <div className="ltn__slide-item-inner text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 align-self-center">
                                    <div className="slide-item-info">
                                        <div className="slide-item-info-inner ltn__slide-animation">
                                            <h3 className="slide-titlee animated ">
                                                De charmantes <br /> maisons
                                                familiales !
                                            </h3>
                                            <div className="slide-brief animated">
                                                <p>
                                                    Contactez-nous dès
                                                    aujourd'hui pour plus
                                                    d'informations ou pour
                                                    planifier une visite.
                                                </p>
                                            </div>
                                            <div className="btn-wrapper animated go-top">
                                                <Link
                                                    to="/maisons"
                                                    className="theme-btn-1 btn btn-effect-1 py-2 px-4"
                                                >
                                                    Découvrir
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default HomeSlides;
