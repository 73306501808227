import React from 'react';

import Navbar2 from '../components/global-components/Navbar2';
import Main from '../components/global-components/Main';
import PageHeader from '../components/global-components/PageHeader';
import Footer from '../components/global-components/Footer';
import Newsletter from '../components/global-components/Newsletter';
import SearchPanel from '../components/global-components/SearchPanel';
import RequestForQuotes from '../components/section-components/request-for-quotes/RequestForQuotes';

/**
 * 
 * @returns 
 */
const Accompaniement = () => {

    return (
        <>
            <Navbar2 />
            <Main>
                <div className='wrapper'>
                    <PageHeader
                        headertitle='Accompagnement'
                        // breadcrumbs={['Concevez avec nous votre projet de A à Z']}
                        />
                    <RequestForQuotes />
                    <Newsletter />
                </div>
            </Main>
            <Footer />
            <SearchPanel />
        </>
    );
};

export default Accompaniement;
