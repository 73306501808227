import React from 'react';

import Navbar2 from '../../../components/global-components/Navbar2';
import PageHeader from '../../../components/global-components/PageHeader';
import Content from '../../../components/real-estate-components/land-components/subdivisions/Content';
import Newsletter from '../../../components/global-components/Newsletter';
import Footer from '../../../components/global-components/Footer';
import Main from '../../../components/global-components/Main';

/**
 * 
 * @returns 
 */
const Subdivisions = () => {

    return (
        <>
            <Navbar2 />
            <Main>
                <div className='wrapper'>
                    <PageHeader
                        headertitle={'Lotissements'}
                        breadcrumbs={['terrains', 'lotissements']}
                    />
                    <Content />
                    <Newsletter />
                </div>
            </Main>
            <Footer />
        </>
    );
};

export default Subdivisions;
