import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import Navbar2 from '../components/global-components/Navbar2';
import PageHeader from '../components/global-components/PageHeader';
import ApartmentSlider from '../components/real-estate-components/apartment-components/ApartmentSlider';
import ApartmentDetails from '../components/real-estate-components/apartment-components/ApartmentDetails';
import Newsletter from '../components/global-components/Newsletter';
import Footer from '../components/global-components/Footer';
import { useDataContext } from '../data/providers/DataProvider';
import { useLoaderSpinnerContext } from '../components/global-components/providers/LoaderSpinnerProvider';
import Main from '../components/global-components/Main';

/**
 * 
 * @returns 
 */
const Apartment = () => {

    const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { apartments } = useDataContext();
    const { id: apartmentId } = useParams();
    const [apartment, setApartment] = useState(undefined);

    useEffect(() => {

        if(apartment){
            setShowLoaderSpinner(false);
        }

    }, [apartment, setShowLoaderSpinner]);

    useEffect(() => {

        const _apartment = apartments.find((l) => l.id === Number(apartmentId));
        setApartment(_apartment);

    }, [apartmentId, apartments]);

    return (
        <>
            <Navbar2 />
            <Main>
                <div className='wrapper'>
                    <PageHeader
                        headertitle="Appartement"
                        customclass="mb-0"
                    />
                    {/* <ApartmentSlider apartment={apartment} /> */}
                    {/* {apartment && <ApartmentSlider apartment={apartment} />}*/}
                    {/* <div
                        className="ltn__sub-breadcrumb-area bg-overlay-theme-black-10 position-relative bg-image w-100"
                        data-bs-bg={`${publicUrl}assets/img/bg/img-${apartment && apartment.real_estate.municipality_id === 466 ? '3' : '2'}.jpg`}
                        style={{
                            zIndex: 0, 
                            minHeight: '350px',
                            // backgroundImage: `url(${publicUrl}assets/img/bg/img-${land && land.real_estate.municipality_id === 466 ? '3' : '2'}.jpg`
                        }}
                    ></div> */}
                    <div className='py-3'></div>
                    {apartment && <ApartmentDetails apartment={apartment} />}
                    <Newsletter />
                </div>
            </Main>
            <Footer />
        </>
    );
};

export default Apartment;
