import React, { useState } from 'react';

import Navbar from '../components/global-components/Navbar';
import Main from '../components/global-components/Main';
import Banner from '../components/section-components/banner-v3';
import ProductListing from '../components/section-components/product-listing';
import Featuresv1 from '../components/section-components/features-v1';
import ProSlider from '../components/section-components/product-slider-v1';
import Apartment from '../components/section-components/apartment-v1';
import VideoV1 from '../components/section-components/video-v1';
import Category from '../components/section-components/category-v1';
import Testimonial from '../components/section-components/testimonial-v1';
import BlogSlider from '../components/blog-components/blog-slider-v1';
import CallToActionV1 from '../components/section-components/call-to-action-v1';
import Footer from '../components/global-components/Footer';
import LandsSlider from '../components/section-components/real-estates/lands/LandsSlider';
import HousesSlider from '../components/section-components/real-estates/houses/HousesSlider-old';
import ApartmentsSlider from '../components/section-components/real-estates/apartments/ApartmentsSlider-old';
import HomeVideo from '../components/section-components/HomeVideo';
import Amenities from '../components/section-components/amenities';
import Newsletter from '../components/global-components/Newsletter';
import Testimonials from '../components/section-components/Testimonials';
import RealEstatesSearch from '../components/section-components/RealEstatesSearch';
import SlideShow from '../components/section-components/SlideShow';
import Services from '../components/section-components/Services';
import RealEstatesSlider from '../components/section-components/real-estates/RealEstatesSlider';
import WhatAreYouLookingFor from '../components/section-components/WhatAreYouLookingFor';
import RealEstatesForSaleSlider from '../components/section-components/real-estates/RealEstatesForSaleSlider';
import RealEstatesForRentSlider from '../components/section-components/real-estates/RealEstatesForRentSlider';
import SearchPanel from '../components/global-components/SearchPanel';
import HomeSlides from '../components/section-components/HomeSlides';
import HomeRightSlides from '../components/section-components/HomeRightSlides';
import HomeTopSlides from '../components/section-components/HomeTopSlides';
import RequestForQuotes from '../components/section-components/request-for-quotes/RequestForQuotes';

/**
 * 
 * @returns 
 */
const Home = () => {
    // const [isLoaded, setLoaded] = useState(false)

    // React.useEffect(() => {
    //     const script = document.createElement("script");
    //     script.async = true
    //     script.src = process.env.PUBLIC_URL + "/assets/js/main.js";
    //     // script.addEventListener("load", () => setLoaded(true));
    //     document.body.appendChild(script);
    // }, []);

    // React.useEffect(() => {
    //     if (isLoaded) {
    //         // ...
    //     }
    // }, [isLoaded])

    // if(!isLoaded){
    //     return <div></div>
    // }
    return (
        <>
            <Navbar CustomClass="ltn__header-transparent gradient-color-2" /> 
            <Main>
                <div className='wrapper'>
                    {/* <Banner /> */}
                    {/* <SlideShow /> */}
                    <div className='container'>
                        <div className='row gy-4'>
                            <div className='col-12'>
                                <HomeTopSlides />
                            </div>
                            <div className='col-lg-8 h-100'>
                                <HomeSlides />
                            </div>
                            <div className='col-lg-4 h-100'>
                                <HomeRightSlides />
                            </div>
                        </div>
                    </div>
                    {/* <RealEstatesSearch /> */}
                    {/* <ProductListing /> */}
                    <div
                        id="homeRealEstatesSlider"
                        className='real-estates-slider pt-120'
                    >
                        {/* <LandsSlider />
                        <HousesSlider />
                        <ApartmentsSlider /> */}
                        <RealEstatesSlider />
                        <RealEstatesForSaleSlider />
                        <RealEstatesForRentSlider />
                        <Services />
                        {/* <div className='row'>
                            <div className='col-12'>
                                <LandsSlider />
                            </div>
                            <div className='col-12'>
                                <HousesSlider />
                            </div>
                            <div className='col-12'>
                                <ApartmentsSlider />
                            </div>
                        </div> */}
                    </div>
                    {/* <LandsSlider /> */}
                    {/* <HousesSlider /> */}
                    {/* <ApartmentsSlider /> */}
                    {/* <Featuresv1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/> */}
                    {/* <ProSlider /> */}
                    {/* <Apartment /> */}
                    {/* <Amenities /> */}
                    <HomeVideo />
                    {/* <WhatAreYouLookingFor /> */}
                    {/* <RequestForQuotes /> */}
                    {/* <Category /> */}
                    <Testimonials />
                    {/* <BlogSlider customClass="section-subtitle-2"/> */}
                    {/* <CallToActionV1 /> */}
                    <Newsletter />
                </div>
            </Main>
            <Footer />
            <SearchPanel />
            {/* <script src={`${process.env.PUBLIC_URL}/assets/js/main.js`} id="mainJs" async></script> */}
        </>
    );
};

export default Home;
